module.exports = [
    {
        "model_id": 1,
        "name": "Linne",
        "src": "../models/Linne2.gltf",
        "x_pos": -25.5,
        "y_pos": 0.1,
        "z_pos": 20.5,
        "x_rot": 0,
        "y_rot": 40,
        "z_rot": 0,
        "x_scale": 1,
        "y_scale": 1,
        "z_scale": 1,
        "property": "show",
        "npc": "true"
    },
    {
        "model_id": 2,
        "name": "Ida",
        "src": "../models/Ida2.gltf",
        "x_pos": -25,
        "y_pos": 0.1,
        "z_pos": 20,
        "x_rot": 0,
        "y_rot": 25,
        "z_rot": 0,
        "x_scale": 1,
        "y_scale": 1,
        "z_scale": 1,
        "property": "show",
        "npc": "true"
    },
    {
        "model_id": 3,
        "name": "Rolfe",
        "src": "../models/Rolfe2.gltf",
        "x_pos": -26,
        "y_pos": 0.1,
        "z_pos": 21,
        "x_rot": 0,
        "y_rot": 70,
        "z_rot": 0,
        "x_scale": 1,
        "y_scale": 1,
        "z_scale": 1,
        "property": "show",
        "npc": "true"
    },
    {
        "model_id": 4,
        "name": "Lejo",
        "src": "../models/Lejo2.gltf",
        "x_pos": -24,
        "y_pos": 0.1,
        "z_pos": 20,
        "x_rot": 0,
        "y_rot": -10,
        "z_rot": 0,
        "x_scale": 1,
        "y_scale": 1,
        "z_scale": 1,
        "property": "show",
        "npc": "true"
    },
    {
        "model_id": 5,
        "name": "Kalie",
        "src": "../models/Kalie2.gltf",
        "x_pos": -26,
        "y_pos": 0.1,
        "z_pos": 22,
        "x_rot": 0,
        "y_rot": 85,
        "z_rot": 0,
        "x_scale": 1,
        "y_scale": 1,
        "z_scale": 1,
        "property": "show",
        "npc": "true"
    },
    {
        "model_id": 6,
        "name": "Sacrificial items",
        "src": "../models/SacrificialItems.gltf",
        "x_pos": 0,
        "y_pos": 0,
        "z_pos": 0,
        "x_rot": 0,
        "y_rot": 0,
        "z_rot": 0,
        "x_scale": 1,
        "y_scale": 1,
        "z_scale": 1,
        "property": "show",
        "npc": "false"
    },
    {
        "model_id": 7,
        "name": "Sacrifice 1 quest item",
        "quest_id": 1,
        "src": "../models/SacrificialQuest1.gltf",
        "x_pos": 0,
        "y_pos": 0,
        "z_pos": 0,
        "x_rot": 0,
        "y_rot": 0,
        "z_rot": 0,
        "x_scale": 1,
        "y_scale": 1,
        "z_scale": 1,
        "property": "hide",
        "npc": "false"
    },
    {
        "model_id": 8,
        "name": "Sacrifice 2 quest item",
        "quest_id": 2,
        "src": "../models/SacrificialQuest2.gltf",
        "x_pos": 0,
        "y_pos": 0,
        "z_pos": 0,
        "x_rot": 0,
        "y_rot": 0,
        "z_rot": 0,
        "x_scale": 1,
        "y_scale": 1,
        "z_scale": 1,
        "property": "hide",
        "npc": "false"
    }
];