module.exports = [
{
    "model_id": 1,
    "name": "Foliage1",
    "src": "models/Foliage1.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 2,
    "name": "Foliage2",
    "src": "models/Foliage2.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 3,
    "name": "Foliage3",
    "src": "models/Foliage3.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 4,
    "name": "Foliage4",
    "src": "models/Foliage4.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 5,
    "name": "Foliage5",
    "src": "models/Foliage5.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 6,
    "name": "Foliage6",
    "src": "models/Foliage6.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 7,
    "name": "Objects1",
    "src": "models/Objects1.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 8,
    "name": "Objects2",
    "src": "models/Objects2.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 9,
    "name": "Objects3",
    "src": "models/Objects3.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 10,
    "name": "Objects4",
    "src": "models/Objects4.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 11,
    "name": "Objects5",
    "src": "models/Objects5.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 12,
    "name": "House1",
    "src": "models/House1.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 13,
    "name": "House2",
    "src": "models/House2.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 14,
    "name": "House3",
    "src": "models/House3.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 15,
    "name": "Water",
    "src": "models/Water.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "static"
},
{
    "model_id": 16,
    "name": "Fire1",
    "src": "models/Fire.gltf",
    "x_pos": 11.8,
    "y_pos": 0,
    "z_pos": -13.5,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 0.3,
    "y_scale": 0.25,
    "z_scale": 0.3,
    "property": "fire"
},
{
    "model_id": 17,
    "name": "Fire2",
    "src": "models/Fire.gltf",
    "x_pos": -35, 
    "y_pos": 1,
    "z_pos": 14.3,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 0.3,
    "y_scale": 0.25,
    "z_scale": 0.3,
    "property": "fire"
},

{
    "model_id": 18,
    "name": "Ground",
    "src": "models/ground.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "ground"
},
{
    "model_id": 19,
    "name": "Dolmen2Ground",
    "src": "models/Dolmen2Ground.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "ground"
},

{
    "model_id": 20,
    "name": "Skybox1",
    "src": "models/Skybox1.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "skybox"
},
{
    "model_id": 21,
    "name": "Skybox2",
    "src": "models/Skybox2.gltf",
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "skybox"
},

{
    "model_id": 22,
    "name": "Dolmen1",
    "src": "models/Dolmen1.gltf",
    "information_id": 1,
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "interactable"
},
{
    "model_id": 23,
    "name": "Dolmen2",
    "src": "models/Dolmen2.gltf",
    "information_id": 1,
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "interactable"
},
{
    "model_id": 24,
    "name": "Pottery",
    "src": "models/Pottery.gltf",
    "information_id": 2,
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "interactable"
},

{
    "model_id": 25,
    "name": "Scratchplough",
    "src": "models/Scratchplough.gltf",
    "information_id": 3,
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "interactable"
},
{
    "model_id": 26,
    "name": "Wheat",
    "src": "models/Wheat.gltf",
    "information_id": 4,
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "interactable"
},
{
    "model_id": 27,
    "name": "Cattle",
    "src": "models/Cattle.gltf",
    "information_id": 5,
    "x_pos": 0,
    "y_pos": 0,
    "z_pos": 0,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "interactable"
},
{
    "model_id": 28,
    "name": "Taurus1",
    "src": "models/Taurus_male.gltf",
    "x_pos": 20,
    "y_pos": 0,
    "z_pos": -13,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 0.8,
    "y_scale": 0.8,
    "z_scale": 0.8,
    "property": "animal"
},
{
    "model_id": 29,
    "name": "Taurus2",
    "src": "models/Taurus_female.gltf",
    "x_pos": 25.5,
    "y_pos": 0,
    "z_pos": -15,
    "x_rot": 0,
    "y_rot": 100,
    "z_rot": 0,
    "x_scale": 0.7,
    "y_scale": 0.7,
    "z_scale": 0.7,
    "property": "animal"
},
{
    "model_id": 30,
    "name": "Boar1",
    "src": "models/Boar.gltf",
    "x_pos": 22,
    "y_pos": 0,
    "z_pos": -14,
    "x_rot": 0,
    "y_rot": -30,
    "z_rot": 0,
    "x_scale": 0.5,
    "y_scale": 0.5,
    "z_scale": 0.5,
    "property": "animal"
},
{
    "model_id": 31,
    "name": "Boar2",
    "src": "models/Boar.gltf",
    "x_pos": 25,
    "y_pos": 0,
    "z_pos": -17,
    "x_rot": 0,
    "y_rot": 60,
    "z_rot": 0,
    "x_scale": 0.4,
    "y_scale": 0.4,
    "z_scale": 0.4,
    "property": "animal"
},
{
    "model_id": 32,
    "name": "Boar3",
    "src": "models/Boar.gltf",
    "x_pos": 17,
    "y_pos": 0,
    "z_pos": -11,
    "x_rot": 0,
    "y_rot": -120,
    "z_rot": 0,
    "x_scale": 0.5,
    "y_scale": 0.5,
    "z_scale": 0.5,
    "property": "animal"
},
{
    "model_id": 33,
    "name": "Pawprint",
    "src": "models/Pawprint.gltf",
    "x_pos": -24,
    "y_pos": 0.0001,
    "z_pos": -2,
    "x_rot": 0,
    "y_rot": -60,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "transparent"
},
{
    "model_id": 34,
    "name": "Pawprint interactable",
    "src": "models/Pawprint_interactable.gltf",
    "information_id": 6,
    "x_pos": -24,
    "y_pos": 0,
    "z_pos": -2,
    "x_rot": 0,
    "y_rot": -60,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "interactable"
},

{
    "model_id": 35,
    "npc_id": 1,
    "name": "Linne",
    "src": "models/Linne1.gltf",
    "audio": "audio/Linne_voice.ogg",
    "dialogue_id": 1,
    "x_pos": 9.82,
    "y_pos": 0.83,
    "z_pos": -7.9,
    "x_rot": 0,
    "y_rot": -30,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "npc"
},
{
    "model_id": 36,
    "npc_id": 2,
    "name": "Ida",
    "src": "models/Ida1.gltf",
    "audio": "audio/Ida_voice.ogg",
    "dialogue_id": 2,
    "x_pos": 10.85,
    "y_pos": 0,
    "z_pos": -14.2,
    "x_rot": 0,
    "y_rot": 50,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "npc"
},
{
    "model_id": 37,
    "npc_id": 3,
    "name": "Rolfe",
    "src": "models/Rolfe1.gltf",
    "audio": "audio/Rolfe_voice.ogg",
    "dialogue_id": 3,
    "x_pos": -15.32,
    "y_pos": 0,
    "z_pos": -14.5,
    "x_rot": 0,
    "y_rot": -90,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "npc"
},

{
    "model_id": 38,
    "name": "Ida Quest",
    "src": "models/BoneTable_quest.gltf",
    "quest_id": 1,
    "x_pos": 14,
    "y_pos": 0,
    "z_pos": -7.2,
    "x_rot": 0,
    "y_rot": 160,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "questInactive"
},
{
    "model_id": 39,
    "name": "Rolfe Quest",
    "src": "models/BirchSap_quest.gltf",
    "quest_id": 2,
    "x_pos": -23,
    "y_pos": 0,
    "z_pos": 14,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "questInactive"
},
{
    "model_id": 40,
    "npc_id": 4,
    "name": "Lejo",
    "src": "models/Lejo1.gltf",
    "audio": "audio/Lejo_voice.ogg",
    "dialogue_id": 4,
    "x_pos": 9,
    "y_pos": -0.255,
    "z_pos": 9,
    "x_rot": 0,
    "y_rot": 10,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "npc"
},
{
    "model_id": 41,
    "npc_id": 5,
    "name": "Kalie",
    "src": "models/Kalie1.gltf",
    "audio": "audio/Kalie_voice.ogg",
    "dialogue_id": 5,
    "x_pos": -20,
    "y_pos": 0,
    "z_pos": -3,
    "x_rot": 0,
    "y_rot": 0,
    "z_rot": 0,
    "x_scale": 1,
    "y_scale": 1,
    "z_scale": 1,
    "property": "npc"
}
];