module.exports = [
    {
        "dialogue_id": 1,
        "name": "Linne",
        "dialogue": "Ik zie het allemaal niet meer zo goed. Gelukkig is mijn dochter Ida een goede hulp. Ze is nu bezig met een pot maken voor het offerfeest."
    },
    {
        "dialogue_id": 2,
        "name": "Ida",
        "dialogue": "De pot moet nog even wat langer bakken",
        "quest_id": 1,
        "questStartDialogue": "Mijn moeder heeft mij al als klein kind geleerd hoe ik het mooist de potten kan versieren. Deze pot is voor het offerfeest, ik heb alleen geen witte kleurpasta meer. Zou jij voor mij wat botpasta kunnen maken?",
        "questDuringDialogue": "Is het gelukt? De werktafel staat vlakbij het vee.",
        "questFinishedDialogue": "Dankjewel, nu kan ik de pot straks gaan inkleuren.",
        "status": "inactive",
        "questTitle": "Botten vermalen",
        "questDescription": "Maak botpasta bij de werktafel zodat Ida de pot kan versieren."
    },
    {
        "dialogue_id": 3,
        "name": "Rolfe",
        "dialogue": "Sorry, ik moet verder met de bijl.",
        "quest_id": 2,
        "questStartDialogue": "De bijl is bijna af voor het offerfeest, maar ik heb nog wat berkenhars nodig om het goed te verzegelen. Naast het hunebed staat een berkenboom, zou jij voor mij wat sap eruit kunnen oogsten?",
        "questDuringDialogue": "Het is de wittige boom bij het hunebed zonder kransstenen.",
        "questFinishedDialogue": "Dit moet genoeg zijn, tot vanavond.",
        "status": "inactive",
        "questTitle": "Berkensap oogsten",
        "questDescription": "Haal wat sap uit de berkenboom zodat rolfe hier straks hars van kan maken."
    },
    {
        "dialogue_id": 4,
        "name": "Lejo",
        "dialogue": "De vissen willen nog niet echt bijten. Ik heb ook wat fuiken in het water, ik hoop dat die meer geluk hebben."
    },
    {
        "dialogue_id": 5,
        "name": "Kalie",
        "dialogue": "Vannacht was er een beer in het dorp, ik weet het zeker! Ik heb pootafdrukken gezien bij het bos!"
    }
];